<template>
  <pagoda-detail-layout-v1
    content-title="社群详情页"
    back-text="返回主列表"
    @back="handleBackToList"
  >
    <template slot="content-form">
      <el-form class="el-form-reset pagoda-form-grid pagoda-form-grid--inline">
        <el-form-item label="群名" class="pagoda-form-grid-cell">
          <p>{{ groupChatInfo.group_name }}</p>
        </el-form-item>
        <el-form-item label="群主" class="pagoda-form-grid-cell">
          <p>{{ groupChatInfo.group_leader }}</p>
        </el-form-item>
        <el-form-item label="群成员总数" class="pagoda-form-grid-cell">
          <p>{{ groupChatInfo.group_members_num }}人</p>
        </el-form-item>
        <el-form-item label="今日退群" class="pagoda-form-grid-cell">
          <p>{{ groupChatInfo.today_out_nums }}</p>
        </el-form-item>
        <el-form-item label="今日加群" class="pagoda-form-grid-cell">
          <p>{{ groupChatInfo.today_add_nums }}</p>
        </el-form-item>
        <el-form-item label="创建时间" class="pagoda-form-grid-cell">
          <p>{{ groupChatInfo.create_time }}</p>
        </el-form-item>
      </el-form>
      <div class="customer-detail">
        <pagoda-sub-title>群成员列表</pagoda-sub-title>
        <div class="pagoda-form-table-layout-con">
          <el-form ref="detailForm" class="el-form-reset" inline>
            <el-form-item label="群成员名称" prop="keyword">
              <el-input
                placeholder="请输入群成员名称"
                v-model="formData.keyword"
              ></el-input>
            </el-form-item>
            <!-- 表单操作按钮 -->
            <div class="pagoda-button-group">
              <el-button @click="handleReset">重置</el-button>
              <el-button type="primary" @click="handleSubmit">查询</el-button>
            </div>
          </el-form>
          <el-table
            ref="table"
            :data="tableData"
            v-loading="isTableLoading"
            element-loading-background="rgba(0, 0, 0, 0)"
            tooltip-effect="light"
          >
            <el-table-column
              prop="member_name"
              width="150"
              label="群成员名称"
            ></el-table-column>
            <el-table-column
              prop="member_type"
              width="150"
              label="成员类型"
            ></el-table-column>
            <el-table-column
              prop="member_join_scene"
              label="进群渠道"
            ></el-table-column>
            <el-table-column
              prop="member_join_time"
              label="进群时间"
              show-overflow-tooltip
            ></el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            v-show="totalDataNum !== 0"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :current-page="pageNumber"
            :total="totalDataNum"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </template>
  </pagoda-detail-layout-v1>
</template>

<script>
import http from '@/services/api/community'
export default {
  data() {
    return {
      formData: {
        page: 1,
        page_size: 10
      },
      groupChatInfo: {},
      customerAddMember: {},
      customerOtherInfo: {
        other_info: {}
      },
      isTableLoading: false,
      tableData: [],
      chat_id: '',
      totalDataNum: 0,
      pageSize: 10,
      pageNumber: 1
    }
  },
  methods: {
    handleSubmit() {
      const params = this.formData
      http
        .getGroupChatMemberList(params)
        .then((res) => {
          this.tableData = res.data.list || []
          this.totalDataNum = res.data.total_num || 0
        })
        .catch(() => {})
    },
    getGroupChatInfo(params) {
      http
        .getGroupChatInfo(params)
        .then((res) => {
          this.groupChatInfo = res.data || {}
        })
        .catch(() => {})
    },
    handleReset() {
      this.formData = {
        page: 1,
        page_size: 10,
        keyword: '',
        chat_id: this.chat_id
      }
      this.pageNumber = 1
      this.pageSize = 10
      this.handleSubmit()
    },
    // pageSize 改变时会触发
    handleSizeChange(val) {
      this.pageSize = val
      this.formData.page_size = val
      this.refreshTableData()
    },
    // currentPage 改变时会触发
    handleCurrentChange(val) {
      this.pageNumber = val
      this.formData.page = val
      this.handleSubmit()
    },
    // 刷新表格数据，重置回第一页
    refreshTableData() {
      this.pageNumber = 1
      this.formData.page = 1
      this.handleSubmit()
    },
    handleBackToList() {
      this.$router.push('/communityManage')
    }
  },
  created() {
    this.formData.chat_id = this.$route.query.chatId
    this.chat_id = this.$route.query.chatId
    this.getGroupChatInfo(this.formData)
    this.handleSubmit(this.formData)
  }
}
</script>
<style lang="less">
.customer-label {
  display: inline-block;
  background-color: #00c587;
  color: #fff;
  border-radius: 3px;
  padding: 3px 10px;
  margin: 0 5px;
  cursor: pointer;
}
.customer-profile {
  padding-top: 10px;
  border-top: 1px solid #e1e6eb;
  border-bottom: 1px solid #e1e6eb;
}
.customer-labels {
  padding-top: 10px;
  .el-form-item__content {
    width: 100%;
  }
}
</style>
